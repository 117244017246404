.background {
    background: rgb(26, 25, 25);
}
.altx {
    padding-bottom: 8vh;
    z-index: 5;
}
.altxline1 {
    width: 3px;
    height: 60px;
    background: rgb(233, 181, 61);
    transform: rotate(65deg);
    position: absolute;
    left: 49.3vw;
}
.altxline2 {
    width: 3px;
    height: 60px;
    background: white;
    transform: rotate(-65deg);
    position: absolute;
    left: 49.3vw;
}
.about-cover {
    padding-top: 8vh;
    padding-bottom: 6vh;
    text-align: center;
}
@media only screen and (max-width: 700px) {
    .about-cover {
        padding-top: 7vh;
        padding-bottom: 4vh;
    }
}
.about-photo {
    height: 30vh;
    border: 2px solid rgb(201, 155, 48);
    background: rgba(124, 122, 122, 0.1);
    padding: 5px;
}
@media only screen and (max-width: 700px) {
    .about-photo {
        height: 23vh;
    }
}

.about-info {
    text-align: center;
    line-height: 3.2vh;
    padding-left: 25vw;
    padding-right: 25vw;
    padding-top: 2vh;
    padding-bottom: 10vh;
    font-size: larger;
}
@media only screen and (max-width: 700px) {
    .about-info {
        padding-left: 15vw;
        padding-right: 15vw;
        font-size: medium;
    }
}