.contact-container {
    padding-bottom: 8.5vh;
    padding-top: 8vh;
}
@media only screen and (max-width: 700px) {
    .contact-container {
        padding-bottom: 7vh;
    }
}

.xline1 {
    position: absolute;
    left: 49.35vw;
    width: 3px;
    height: 60px;
    background-color: white;
    transform: rotate(65deg);

}
.xline2 {
    position: absolute;
    left: 49.35vw;
    left: 49.35vw;
    width: 3px;
    height: 60px;
    background-color: rgb(233, 181, 61);
    transform: rotate(-65deg);
}

.flex {
    padding-left: 13vw;
    padding-right: 13vw;
    padding-top: 15vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
@media only screen and (max-width: 700px) {
    .flex {
        flex-direction: column;
        text-align: center;
        padding-top: 11vh;
    }
    .normal-item {
        padding-top: 2.2vh;
    }
}

