.nav-transparent {
    z-index: 120;
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 8vh;
    width: 100%;
    background: rgba(25, 25, 26, 0.7);
}
.nav-container {
    z-index: 120;
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 8vh;
    width: 100%;
    background: rgba(25, 25, 26, 1);
    border-bottom:1px rgb(233, 181, 61)
}


.brandName {
    display: flex;
    align-items: center;
    padding-left: 4vh;
    padding-right: 3vh;
    font-size: 4vh;
    height: 100%;
    width: 57%;
    /* color: rgb(167, 125, 27); */
    color: rgb(233, 181, 61);
}
@media only screen and (max-width: 700px) {
    .brandName {
        font-size: 2.7vh;
        padding-right: 0vh;
        padding-left: 1vh;
        width: 48%;
        text-decoration: none;
    }
}


.pages {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 32%;
    justify-content: space-around;
    align-items: center;
    font-size: 2.6vh;
    padding-right: 3vh;
    text-decoration: none;
}
@media only screen and (max-width: 700px) {
    .pages {
        font-size: 1.9vh;
        padding-right: 1vh;
        width: 52%;
    }
}
.page {
    text-decoration: none;
    color: white;
}
.specialColor {
    color: rgb(233, 181, 61);
    text-decoration: none;
}
.active {
    color: rgb(233, 181, 61);
    text-decoration: none;
  }