.overlay {
  position: fixed;
  top: 15vh;
  left: 20vw;
  z-index: -1;
  /* width: 100%;
  height: 100%; */
  background: rgba(124, 122, 122, 0.1);
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 1s;
  border: 2px solid rgb(201, 155, 48);
  padding: 5px;
}

.overlay img{
    /* width: 400px; */
  max-width: 90vw;
  /* max-height: 90%; */
  /* width: auto;
  height: auto; */
  /* transform: scale(0.95); */
  transition: opacity 3s;
}

.overlay:target {
  z-index: 99;
  opacity: 1;
  outline: none;
  /* cursor: default; */
}

/* .overlay:target img {
    transform: scale(1);
} */

.overlay_h {
    width: 750px;
}
.overlay_v {
    height: 500px;
}



.yellowLink {
    color: rgb(201, 155, 48);
    text-decoration: inherit;
}
.whiteLink {
    color: white;
    text-decoration: inherit;
}

.altx {
    padding-bottom: 2vh;
    z-index: 5;
}
.altxline1 {
    width: 3px;
    height: 60px;
    background: rgb(233, 181, 61);
    transform: rotate(65deg);
    position: absolute;
    left: 49.3vw;
}
.altxline2 {
    width: 3px;
    height: 60px;
    background: white;
    transform: rotate(-65deg);
    position: absolute;
    left: 49.3vw;
}
.works-title {
    text-align: center;
    font-size: 3vw;
    padding-top: 8vh;
    color: rgb(233, 181, 61);
}
@media only screen and (max-width: 700px) {
    .works-title {
        font-size: 3.7vh;
    }
}
.categories {
    padding-top: 4vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
}
.category-title {
    text-align: center;
    padding-top: 8vh;
    padding-right: 3vw;
    margin-bottom: 10px;
    font-size: 2vw;
}
@media only screen and (max-width: 700px) {
    .category-title {
        font-size: 2.4vh;
        margin: auto;
        padding-top: 4vh;
        padding-bottom: 0;
    }
}
.category-boxing {
    padding-top: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.left-title {
    font-size: large;
    position: fixed;
    left: 3vw;
    top: 19vh;
    /* padding-left: 4vw;
    padding-top: 18vh; */
}
@media only screen and (max-width: 700px) {
    .left-title {
        /* padding-right: 2vw; */
        left: 1vw;
        font-size: 1.8vh;
    }
    .picture-grid {
        padding-left: 5vw;
    }
}
.piece_h {
    margin-top: 190px;
    width: 250px;
    margin-left: 8px;
    margin-right: 8px;
}
.piece_v {
    margin-top: 190px;
    height: 180px;
    margin-left: 8px;
    margin-right: 8px;
}
.category-items {
    width: 70vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    flex: 50%;
}
@media only screen and (max-width: 700px) {
    .category-items {
        width: 63vw;
    }
}
/* .item {
    display: flex;
    flex-direction: column;
    padding-bottom: 8vh;
}
.item:last-child {
    padding-bottom: 0;
}
.item-hover {
    position: absolute;
    height: 30vh;
    background: rgba(25, 25, 26, 0.7);
    margin-left: 4.5px;
    margin-top: 4.5px;
    display: none;
    line-height: 30vh;
    color: rgb(201, 155, 48);
    width: 48vh;
}
.item-image:hover + .item-hover {
    display: flex; 
    flex-direction: row; 
    justify-content: space-around;
}
.item-image img {
    height: 30vh;
    border: 1.5px solid rgb(201, 155, 48);
    padding: 3px;
}
.price {
    margin-left: 2vh;
}
.add-to-cart {
    text-decoration: underline;
} */



/* .workrow {
    width: 70vw;
    display: flex;
    flex-direction: row;
} */
.category-flex {
    /* display: flex; */
    display: block;
    /* flex: column; */
    padding-left:3.2vw;
}
/* @media only screen and (max-width: 700px) {
    .category-flex {
        display: block;
    }
} */
.row_item img {
    width: 7vh;
    height: 7vh;
}
.item1 img {
    object-fit: contain;
}
.item2 img {
    object-fit: contain;
}
.item3 img {
    object-fit: contain;
}


.picture-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: calc(70vw - 2vw);
  }
  
.grid-picture {
    display: flex;
    max-width: calc(50% - 3vw);
    max-height: 65vh;
    justify-content: center;
    vertical-align: middle;
    margin: auto auto;
    padding: 7vh 0.5vw;
}

@media only screen and (max-width: 700px) {
    .grid-picture {
        display: flex;
        max-width: 80%;
        max-height: 65vh;
        justify-content: center;
        vertical-align: middle;
        margin: auto auto;
        padding: 7vh 0.5vw;
    }
}

.video {
    max-width: 60vw;
    max-height: 60vh;
}

.videos {
    padding: 7vh 0;
} 